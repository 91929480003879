/** @jsxImportSource @emotion/react */
import * as React from 'react'
import * as mq from 'utils/media-queries'
import {
	Title,
	TitleGroup,
	TitleImage,
	Divider,
	Icon,
	RouteButton,
} from 'utils/lib'
// import locationLasVegas from 'assets/location-las-vegas.jpg'
// import locationLasVegasTruck from 'assets/location-las-vegas-truck.jpg'
import locationTruck from 'assets/location-los-angeles-truck.jpg'
import locationTabletop from 'assets/location-tabletop.jpg'
import facebookLogo from 'assets/logo-black-facebook.png'
import yelpLogo from 'assets/logo-black-yelp.png'
import instagramLogo from 'assets/logo-black-instagram.png'
// import tiktokLogo from 'assets/logo-black-tiktok.png'
import bunnyEating from 'assets/title-bunny-eating.png'
// import bunnyHappy from 'assets/title-happy-bunny.png'
import whaleHappy from 'assets/title-happy-whale.png'

function LocationImage({src, alt}) {
	return (
		<div
			css={{
				marginTop: '2rem',
				marginBottom: '2rem',
				display: 'flex',
				justifyContent: 'center',
				minWidth: '320px',
			}}
		>
			<img
				alt={alt}
				src={src}
				css={{
					boxShadow: '0px 1px 8px 0px rgb(150, 150, 150, 1)',
					width: '100%',
					outline: '1px solid #ddd',
					[mq.extraSmall]: {
						maxWidth: '350px',
						height: '270px',
					},
					[mq.small]: {
						maxWidth: '500px',
						height: '335px',
					},
					[mq.medium]: {
						maxWidth: '800px',
						height: '540px',
					},
					[mq.large]: {
						maxWidth: '800px',
						height: '540px',
					},
				}}
			/>
		</div>
	)
}

function AddressContainer({...props}) {
	return (
		<div
			css={{
				marginTop: '2rem',
				marginBottom: '2rem',
				minWidth: '320px',
			}}
			{...props}
		/>
	)
}

function AddressTitle({customcss, children}) {
	return (
		<div
			css={{
				fontWeight: 'bold',
				fontFamily: 'Open Sans, sans-serif',
				fontSize: '1.1rem',
				marginTop: '1rem',
				marginBottom: '1rem',
				...customcss,
			}}
		>
			{children}
		</div>
	)
}

function AddressLine(props) {
	return (
		<div
			css={{
				marginTop: '0.5rem',
				marginBottom: '0.5rem',
				marginLeft: '1.75rem',
				marginRight: '1.75rem',
			}}
			{...props}
		/>
	)
}

function Locations() {
	return (
		<div
			css={{
				marginBottom: '3rem',
			}}
		>
			{/* Los Angeles Truck */}
			<TitleGroup>
				<Title id="location-truck">TRUCK LOS ANGELES</Title>
				<TitleImage src={whaleHappy} alt="happy-whale" />
			</TitleGroup>

			<LocationImage src={locationTruck} alt="location-los-angeles-truck" />

			<AddressContainer>
				<AddressTitle customcss={{marginTop: '2rem'}}>Pricing:</AddressTitle>
				<AddressLine>
					Truck events start at $700 sales minimum and includes (100) servings
				</AddressLine>
				<AddressTitle customcss={{marginTop: '2rem'}}>Includes:</AddressTitle>
				<AddressLine>(2) Hours of Service</AddressLine>
				<AddressLine>Full menu of all (13) signature combinations</AddressLine>
				<AddressLine css={{marginBottom: '1.25rem'}}>
					Made to order dessert on site
				</AddressLine>
				<AddressLine>
					*Additional Charges: Service Fee (varies depending on location),
					Additional Hours
				</AddressLine>

				<RouteButton css={{marginTop: '1.5rem'}} to="/catering">
					BOOK NOW
				</RouteButton>

				<div
					css={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '2rem',
						marginBottom: '2rem',
					}}
				>
					<Icon
						alt="instagram-logo"
						src={instagramLogo}
						href="https://www.instagram.com/fluffice/"
						width="40px"
						height="40px"
					/>
					<Icon
						alt="yelp-logo"
						src={yelpLogo}
						href="https://www.yelp.com/biz/fluff-ice-truck-rosemead"
						width="40px"
						height="40px"
					/>
					<Icon
						alt="facebook-logo"
						src={facebookLogo}
						href="https://www.facebook.com/fluffice/"
						width="40px"
						height="40px"
					/>
				</div>
			</AddressContainer>

			<Divider />

			{/* Las Vegas */}
			{/* <TitleGroup>
        <Title id='location-las-vegas'>LAS VEGAS</Title>
        <TitleImage src={bunnyHappy} alt='happy-bunny'/>
      </TitleGroup>

      <LocationImage 
        src={locationLasVegas} 
        alt='location-las-vegas'
      />
      
      <AddressContainer>
        <AddressTitle>Address:</AddressTitle>     
        <AddressLine>325 North Nellis Boulevard</AddressLine>
        <AddressLine>Las Vegas, NV 89110</AddressLine>
        <AddressLine>
          702-202-0810 | <a css={{
            textDecoration: 'none',
            color: '#000',
            ':hover': {
              textDecoration: 'underline',
            }
          }} href='mailto:info@flufficelv.com'>info@flufficelv.com</a>
        </AddressLine>
        <AddressTitle customcss={{marginTop: '2rem'}}>Hours:</AddressTitle>
        <AddressLine>Sun-Thurs | 12:00 PM - 6:00 AM</AddressLine>
        <AddressLine>Fri-Sat | 12:00 PM - 7:00 PM</AddressLine>

        <div css={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2rem',
          marginBottom: '2rem',
        }}>
          <Icon 
            alt='instagram-logo' src={instagramLogo} 
            href='https://www.instagram.com/fluffice.lv'
            width='40px'
            height='40px'
          />
          <Icon 
            alt='yelp-logo' 
            src={yelpLogo} 
            href='https://www.yelp.com/biz/fluff-ice-las-vegas-3'
            width='40px'
            height='40px'
          />
          <Icon
            alt='tiktok-logo'
            src={tiktokLogo}
            href='https://www.tiktok.com/@flufficelv?lang=en'
            width='40px'
            height='40px'
          />
          <Icon 
            alt='facebook-logo' 
            src={facebookLogo} 
            href='https://www.facebook.com/FluffIceLV/'
            width='40px'
            height='40px'
          />
        </div>
      </AddressContainer> */}

			{/* <Divider/> */}

			{/* Las Vegas Truck */}
			{/* <TitleGroup>
        <Title id='location-las-vegas-truck'>TRUCK LAS VEGAS</Title>
        <TitleImage src={bunnyEating} alt='bunny-eating'/>
      </TitleGroup>

      <LocationImage src={locationLasVegasTruck} alt='location-las-vegas-truck'/>
      
      <AddressContainer>
        <AddressTitle>Address:</AddressTitle>
        <AddressLine>Based out of Las Vegas</AddressLine>
        
        <AddressTitle customcss={{marginTop: '2rem'}}>Availability:</AddressTitle>
        <AddressLine>Please reach out to Dominique for</AddressLine>
        <AddressLine>Fluff Ice Las Vegas Truck availability.</AddressLine>
        <AddressLine>702-936-2613
          | 
          <a 
            css={{
              textDecoration: 'none',
              color: '#000',
              ':hover': {
                textDecoration: 'underline',
              }
            }} 
            href='mailto:info@flufficelv.com'
          >info@flufficelv.com</a>
        </AddressLine>

        <div css={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2rem',
          marginBottom: '2rem',
        }}>
          <Icon 
            alt='instagram-logo' src={instagramLogo} 
            href='https://www.instagram.com/fluffice.lv'
            width='40px'
            height='40px'
          />
          <Icon 
            alt='yelp-logo' 
            src={yelpLogo} 
            href='https://www.yelp.com/biz/fluff-ice-las-vegas-3'
            width='40px'
            height='40px'
          />
          <Icon
            alt='tiktok-logo'
            src={tiktokLogo}
            href='https://www.tiktok.com/@flufficelv?lang=en'
            width='40px'
            height='40px'
          />
          <Icon 
            alt='facebook-logo' 
            src={facebookLogo} 
            href='https://www.facebook.com/FluffIceLV/'
            width='40px'
            height='40px'
          />
        </div>
      </AddressContainer> */}

			<TitleGroup>
				<Title id="location-tabletop">TABLETOP</Title>
				<TitleImage src={bunnyEating} alt="bunny-eating" />
			</TitleGroup>

			<LocationImage src={locationTabletop} alt="location-tabletop" />

			<AddressContainer>
				<AddressTitle customcss={{marginTop: '2rem'}}>Pricing:</AddressTitle>
				<AddressLine>
					Tabletop services start at $950 sales minimum and includes (135)
					servings
				</AddressLine>

				<AddressTitle customcss={{marginTop: '2rem'}}>Includes:</AddressTitle>
				<AddressLine>(2) Hours of Service</AddressLine>
				<AddressLine>(4) Fluff Ice Signature Combinations</AddressLine>
				<AddressLine css={{marginBottom: '1.25rem'}}>
					Made to order dessert on site
				</AddressLine>
				<AddressLine>
					*Additional Charges: Service Fee (varies depending on location),
					Additional Hours, Additional Combinations
				</AddressLine>

				<RouteButton css={{marginTop: '1.5rem'}} to="/catering">
					BOOK NOW
				</RouteButton>
			</AddressContainer>

			<AddressTitle customcss={{marginTop: '2rem'}}>Availability:</AddressTitle>
			<AddressLine>Contact us to see if we can</AddressLine>
			<AddressLine>cater at your next big event!</AddressLine>
			<AddressLine>
				626-400-0594 |{' '}
				<a
					css={{
						textDecoration: 'none',
						color: '#000',
						':hover': {
							textDecoration: 'underline',
						},
					}}
					href="mailto:nick@fluffice.com"
				>
					nick@fluffice.com
				</a>
			</AddressLine>
		</div>
	)
}

function LocationsScreen() {
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<div>
			<Locations />
		</div>
	)
}

export {LocationsScreen}
